import BigNumber from 'bignumber.js';
import { useState } from 'react';
import checkIcon from '../../../assets/check.png';
import { ConnectForm } from '../../../hooks/form';

import * as Input from './styles';

import {
  CardsContainer,
  CardContent,
  CardLabel,
  DoubleLabelRow,
  Label,
  CheckboxContainer,
  CheckboxContent,
  Circle,
  InputCustomComponent,
  InputContainer,
  MaxButton,
} from './styles';

interface ICard {
  name: string;
  value: string;
  icon: React.ReactNode;
}

interface CardProps {
  cards: ICard[];
  onSelect?: (card: ICard) => void;
  selectedCard?: string;
}

interface DoubleLabelProps {
  labels: [string, string];
  disableBold?: boolean;
}

interface CheckboxOption {
  labels: [string, string];
  value: string;
}

interface CheckboxProps {
  options: CheckboxOption[];
  defaultOption?: CheckboxOption;
}

export interface DefaultProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  maxButton?: string;
  icon?: React.ReactNode;
  onChangeValue?: (value: string) => void;
  forcedValue?: string;
  registerField?: { name: string; options?: any };
}

const Cards: React.FC<CardProps> = ({ cards, onSelect, selectedCard }) => {
  const handleCardClick = (card: ICard) => {
    onSelect?.(card);
  };

  return (
    <CardsContainer>
      {cards.map((card, index) => (
        <CardContent
          onClick={() => handleCardClick(card)}
          selected={card.value === selectedCard}
          key={index}
        >
          {card.icon}
          <CardLabel>{card.name}</CardLabel>
        </CardContent>
      ))}
    </CardsContainer>
  );
};

const DoubleLabel: React.FC<DoubleLabelProps> = ({ labels, disableBold }) => {
  return (
    <DoubleLabelRow>
      {labels.map((label, index) => (
        <Label disableBold={disableBold} key={index}>
          {label}
        </Label>
      ))}
    </DoubleLabelRow>
  );
};

const Checkbox: React.FC<CheckboxProps> = ({ options, defaultOption }) => {
  const [selected, setSelected] = useState<string>(defaultOption?.value);

  const handleSelect = (value: string) => {
    setSelected(value);
  };

  return (
    <CheckboxContainer>
      {options.map((option, index) => (
        <CheckboxContent key={index}>
          <Circle onClick={() => handleSelect(option.value)}>
            {selected === option.value && (
              <img src={checkIcon} alt="check icon" />
            )}
          </Circle>
          <DoubleLabel disableBold labels={option.labels} key={index} />
        </CheckboxContent>
      ))}
    </CheckboxContainer>
  );
};

const Default: React.FC<DefaultProps> = props => {
  const {
    maxButton,
    icon,
    onChangeValue,
    forcedValue,
    registerField,
    ...rest
  } = props;
  const isForcedValue = forcedValue !== undefined;

  const [value, setValue] = useState<string | number>('');

  const handleSetMaxValue = () => {
    setValue(maxButton);
    onChangeValue?.(maxButton);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;

    if (maxButton) {
      const isValid = new BigNumber(newValue).isLessThanOrEqualTo(
        new BigNumber(maxButton)
      );

      if (isValid) {
        setValue(newValue);
        onChangeValue?.(newValue);
      }
    } else {
      setValue(newValue);
      onChangeValue?.(newValue);
    }
  };

  return (
    <ConnectForm>
      {({ register, setValue: setInputValue }) => (
        <InputContainer>
          {icon && <div className="alignIcon">{icon}</div>}
          {register ? (
            <InputCustomComponent
              {...rest}
              {...register(registerField?.name, registerField?.options)}
              autoComplete="new-password"
              maxButton={Boolean(maxButton)}
            />
          ) : (
            <InputCustomComponent
              {...rest}
              autoComplete="new-password"
              maxButton={Boolean(maxButton)}
              value={isForcedValue ? forcedValue : value}
              onChange={handleChange}
            />
          )}
          {maxButton ? (
            <MaxButton
              type="button"
              onClick={() => {
                handleSetMaxValue(),
                  setInputValue(registerField?.name, maxButton);
              }}
            >
              MÁX
            </MaxButton>
          ) : undefined}
        </InputContainer>
      )}
    </ConnectForm>
  );
};

export default {
  Checkbox,
  DoubleLabel,
  Cards,
  Default,
  ...Input,
};
