import { toast } from 'react-toastify';

export function toastError(error?: string | any) {
  let finalMessage: string = '';

  // Revisar logica de erros
  if (error?.hasOwnProperty('response')) {
    if (error?.response.hasOwnProperty('data')) {
      if (error?.response.data.hasOwnProperty('message')) {
        finalMessage = error?.response.data.message;
      }
    } else if (error?.response.hasOwnProperty('message')) {
      finalMessage = error?.reponse.message;
    }
  } else if (error?.hasOwnProperty('message')) {
    finalMessage = error?.message;
  } else {
    finalMessage = error;
  }

  return toast.error(
    finalMessage !== '' && finalMessage !== undefined
      ? finalMessage
      : 'Erro ao Realizar a Operação',
    {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );
}

export function toastSuccess(msg?: string) {
  toast.success(msg ? msg : 'Operação realizada com sucesso!', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}

export function toastInfo(msg: string) {
  toast.info(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}
