export function isOnPopQuest(event) {
    if(event.detail === null) {
      return false;
    }

    if(event.detail.inAppUrl && event.detail.dataFromPage && event.detail.inAppUrl.startsWith('/coingoback-quest-navigation/quests/')) {
      let dataFromPage = event.detail.dataFromPage;

      if(dataFromPage.quest) {
        return true;
      }
    }

    return false;
  };
