import { compare, validate } from "compare-versions";

export function isAppV2() {
    const mobileVersion = localStorage.getItem('@coingoback:mobileVersion');

    if (mobileVersion !== undefined && validate(mobileVersion)) {
        return compare(mobileVersion, '2.0.0', '>=');
    }

    return false;
}

export async function getExtensionVersion() {
    try {
        let res = await fetch('chrome-extension://cbohbbfalnakfabmclapcglchjnpljdm/info.json');
        return (await res.json())['version'];
    } catch(e) {
        return '2.3.2';
    }
}

export function isOldApp() {
    const mobileVersion = localStorage.getItem('@coingoback:mobileVersion');

    if (mobileVersion !== undefined && validate(mobileVersion)) {
        return compare(mobileVersion, '2.0.0', '<');
    }

    return false;
}
