import React from 'react';

import Dialog from '@mui/material/Dialog';

import { Container, ScrollContent } from './styles';
import Close from '../../assets/close.svg';
import { Slide, SlideProps, useMediaQuery } from '@mui/material';
import theme from '../../styles/theme';

interface Props {
  open: boolean;
  onClose: () => void;
  primary?: boolean;
}

const Modal: React.FC<Props> = ({ children, open, onClose, primary }) => {
  const isDesktop = useMediaQuery('(min-width: 770px)');

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      TransitionComponent={Slide}
      TransitionProps={{direction: 'up'} as SlideProps}
      fullScreen={!isDesktop ? true : false}
      style={isDesktop ? {} : {
        paddingTop: 48
      }}
      PaperProps={{
        style: {
          ...isDesktop ? {
            boxShadow: '6px 6px #000',
            border: '1px solid #000',
            borderRadius: 22,
            overflow: 'hidden',
            // width: '100%',
          } : {
            borderRadius: '22px 22px 0px 0px',
          },
          ...primary ? {backgroundColor: theme.colors.primary.main, boxShadow: '6px 6px '+theme.colors.primary.main} : {},
        }
      }}
      onClose={handleClose}
      aria-labelledby="modal"
      open={open}
    >
      <Container>
        <div style={{padding: 0, width: '100%', textAlign: 'right'}}>
        <button type="button" onClick={onClose}>
          <Close className="button-modal" />
        </button>
        </div>
        <ScrollContent>{children}</ScrollContent>
      </Container>
    </Dialog>
  );
};

export default Modal;
