import { Collapse } from '@mui/material';
import { useState } from 'react';
import { Container, MenuCard, TheCards } from './styles';

import Cashback from '../../../../assets/Homeicons/Cashback.svg';
import Noticias from '../../../../assets/Homeicons/Noticias.svg';
import CompraCrypto from '../../../../assets/Homeicons/CompraCrypto.svg';
import Wallet from '../../../../assets/Homeicons/Carteira.svg';
import Analises from '../../../../assets/Homeicons/Analises.svg';
import Varrow from '../../../../assets/V-arrow.png';
import router from 'next/router';

const ColapseCards = () => {
  const [showMenu, setShowMenu] = useState<boolean>(null);

  const toggleMenu = () => {
    if (showMenu === null || showMenu === false) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  };

  return (
    <Container>
      <TheCards>
        <button
          onClick={() => {
            router.push('/stores');
          }}
        >
          <MenuCard>
            <Cashback />
            <span>Criptoback</span>
          </MenuCard>
        </button>
        <button
          onClick={() => {
            router.push('/cashout');
          }}
        >
          <MenuCard>
            <Wallet />
            <span>Sacar</span>
          </MenuCard>
        </button>
        <button
          onClick={() => {
            router.push('/market');
          }}
        >
          <MenuCard>
            <Analises />
            <span>Mercado</span>
          </MenuCard>
        </button>
      </TheCards>
      <Collapse in={showMenu} timeout={700} unmountOnExit>
        <TheCards>
          <button
            onClick={() => {
              router.push('/stores?tag=buy-crypto');
            }}
          >
            <MenuCard>
              <CompraCrypto />
              <span>
                Compre <br /> e ganhe
              </span>
            </MenuCard>
          </button>

          <button onClick={() => (window.location.href = '/news')}>
            <MenuCard>
              <Noticias />
              <span>Noticias</span>
            </MenuCard>
          </button>
          <button>
            <a
              href="https://club.cointimes.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuCard>
                <Analises />
                <span>Educação</span>
              </MenuCard>
            </a>
          </button>
        </TheCards>
      </Collapse>
      <div
        className={
          showMenu === null
            ? 'arrow'
            : showMenu === true
            ? 'arrow show'
            : 'arrow hide'
        }
      >
        <button onClick={toggleMenu}>
          <img src={Varrow} alt="arrow icon" />
        </button>
      </div>
    </Container>
  );
};

export default ColapseCards;
