import { useMemo } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  fromPromise,
  ApolloLink,
  DefaultOptions,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { concatPagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import axiosClient from './axiosClient';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import getTimezoneCountry from '../utils/getTimezoneCountry';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;
let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const fromPath = () => {
  const pathname = window.location.pathname;
  return pathname.replace(/\//g, '%2F');
};

const handleRefreshToken = async () => {
  const refreshToken = localStorage.getItem('@coingoback:refreshToken');

  if (!refreshToken) {
    window.location.href = `/login?from=${fromPath()}`;
    return;
  }

  return await axiosClient
    .post('/api/oauth/token/site', {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    })
    .then(async response => {
      localStorage.setItem('@coingoback:token', response.data.access_token);
      localStorage.setItem(
        '@coingoback:tokenExpiration',
        new Date().setDate(new Date().getDate() + 1).toString()
      );
      localStorage.setItem(
        '@coingoback:refreshToken',
        response.data.refresh_token
      );

      return response.data;
    })
    .catch(error => {
      console.log(`[Refresh error]: ${error}`);
      window.location.href = `/login?from=${fromPath()}`;
    });
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.category) {
          case 'authentication':
            if (!isRefreshing) {
              setIsRefreshing(true);

              return fromPromise(
                handleRefreshToken().catch(error => {
                  resolvePendingRequests();
                  setIsRefreshing(false);
                  console.log(`[Promise error]: ${error}`);

                  return forward(operation);
                })
              ).flatMap(() => {
                resolvePendingRequests();
                setIsRefreshing(false);

                return forward(operation);
              });
            } else {
              return fromPromise(
                new Promise<void>(resolve => {
                  addPendingRequest(() => resolve());
                })
              ).flatMap(() => {
                return forward(operation);
              });
            }
        }
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

export const httpLink = new HttpLink({ uri: process.env.NEXT_PUBLIC_API_URL });

const authLink = new ApolloLink((operation, forward) => {
  const isServer = typeof window === 'undefined';
  if (!isServer) {
    const token = localStorage.getItem('@coingoback:token');
    let country = localStorage.getItem('@coingoback:localCountry');

    if(!country) {
      country = getTimezoneCountry();
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `${token ? 'Bearer ' + token : undefined}`,
        'x-country': country,
        'x-brand': 'COINGOBACK',
      },
    }));

    return forward(operation);
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-country': 'BR',
      'x-brand': 'COINGOBACK',
    },
  }));

  return forward(operation);
});

const refreshTokenLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () => {
    const isServer = typeof window === 'undefined';
    if (!isServer) {
      const token = localStorage.getItem('@coingoback:token');
      const tokenExpiration = Number(
        localStorage.getItem('@coingoback:tokenExpiration')
      );
      const isTokenExpired = new Date() > new Date(tokenExpiration);

      return !token || (token && !isTokenExpired);
    }
    return true;
  },
  fetchAccessToken: () => handleRefreshToken(),
  handleFetch: (response: any) => {
    if (response.error) return;
    return response;
  },
  handleError: err => {
    console.log(`[Refresh token error]: ${err}`);
    window.location.href = `/login?from=${fromPath()}`;
  },
  handleResponse: (operation, accessTokenField) => response => {
    return response;
  },
});

export const linkWithoutHttpLink = ApolloLink.from([refreshTokenLink, errorLink, authLink]);

const link = ApolloLink.from([linkWithoutHttpLink, httpLink]);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache(),
    defaultOptions,
  });
}

export function getWithHttpLink(link) {
  return ApolloLink.from([link, httpLink]);
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
