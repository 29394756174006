import { ApolloClient, ApolloLink, gql } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { CompanyQuestsPreview, QuestByCompanySlug, QuestCompany, Wave } from '../models/quests';
import { getWithHttpLink, initializeApollo, linkWithoutHttpLink } from '../services/apolloClient';

const apolloClient = initializeApollo();

function getApolloClientForDevice(device_id: string) {
  let deviceIdHeaderLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        'authorization': undefined,
        'x-device-id': device_id,
      },
    }));

    return forward(operation);
  });

  const customApolloClient = new ApolloClient({
    ssrMode: apolloClient.ssrMode,
    link: getWithHttpLink(linkWithoutHttpLink.concat(deviceIdHeaderLink)),
    cache: apolloClient.cache,
    defaultOptions: apolloClient.defaultOptions,
  });

  return customApolloClient;
}

interface QuestsContextData {
  getCompanyQuestPreviews: (device_id?: string) => Promise<CompanyQuestsPreview[]>;
  getQuestsByCompanySlug: (company_slug: string, limit?: number, offset?: number, not_earned?: boolean, tags?: string[], device_id?: string) => Promise<QuestByCompanySlug[]>;
  getQuestCompanyBySlug: (slug: string) => Promise<QuestCompany>;
  getWavesByCompanyId: (company_id: string) => Promise<Wave[]>;
}

function refreshQuestOnExtension() {
  setTimeout(function () {
    let customEvent = new CustomEvent("refreshQuestsExtension");
    window.dispatchEvent(customEvent);
  }, 1000);
}

const getQuestCompanyBySlug = async (slug: string): Promise<QuestCompany> => {
  return new Promise<QuestCompany>((resolve, reject) => {
    apolloClient
      .query({
        query: gql`
          query QuestCompanyBySlug($slug: String!) {
            questCompanyBySlug(slug: $slug) {
              id
              name
              image
              description
            }
          }
        `,
        variables: {
          slug,
        },
      })
      .then(async response => {
        resolve(response?.data?.questCompanyBySlug);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const QuestsContext = createContext<QuestsContextData>({} as QuestsContextData);

export const QuestsProvider: React.FC = ({ children }) => {
  const getCompanyQuestPreviews = async (device_id?: string): Promise<CompanyQuestsPreview[]> => {
    return new Promise<CompanyQuestsPreview[]>((resolve, reject) => {

      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query companyQuestPreviews {
              companyQuestPreviews {
                id
                image
                image_small
                name
                description
                slug
                currency
                amount
                amount_formatted
                quests_count
                completed_quests_count
              }
            }
          `
      })
        .then(async response => {
          resolve(response?.data?.companyQuestPreviews);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getQuestsByCompanySlug = async (company_slug: string, limit?: number, offset?: number, not_earned?: boolean, tags?: string[], device_id?: string): Promise<QuestByCompanySlug[]> => {
    return new Promise<QuestByCompanySlug[]>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query QuestsByCompanySlug($company_slug: String!, $limit: Int, $offset: Int, $not_earned: Boolean, $tags: [String]) {
              questsByCompanySlug(company_slug: $company_slug, limit: $limit, offset: $offset, not_earned: $not_earned, tags: $tags) {
                id
                title
                description
                image
                reward_currency
                reward_amount
                reward_amount_formatted
                period_type
                is_budget
                quest_reward {
                    id
                    account_id
                    currency
                    amount
                    amount_formatted
                }
                quest_link {
                  initial_url
                  url
                  time_seconds
                }
              }
            }
          `,
        variables: {
          company_slug,
          limit,
          offset,
          not_earned,
          tags
        },
      })
        .then(async response => {
          let questsByCompanySlug = response?.data?.questsByCompanySlug;
          questsByCompanySlug = questsByCompanySlug.map(function (quest) {
            quest.is_rewarded = quest.quest_reward ? true : false;
            return quest;
          })

          resolve(questsByCompanySlug);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getWavesByCompanyId = async (company_id: string): Promise<Wave[]> => {
    return new Promise<Wave[]>((resolve, reject) => {
      const client = apolloClient;

      client.query({
        query: gql`
            query WavesByCompanyId($company_id: String!) {
              wavesByCompanyId(company_id: $company_id) {
                id
                name
                description
                image
                sort
                is_completed
              }
            }
          `,
        variables: {
          company_id,
        },
      })
        .then(async response => {
          resolve(response?.data?.wavesByCompanyId);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <QuestsContext.Provider value={{ getCompanyQuestPreviews, getQuestsByCompanySlug, getQuestCompanyBySlug, getWavesByCompanyId }}>
      {children}
    </QuestsContext.Provider>
  );
};

function useQuests(): QuestsContextData {
  const context = useContext(QuestsContext);

  if (!context) {
    throw new Error(' useQuests must be used within an QuestsProvider ');
  }
  return context;
}

export { QuestsContext, useQuests, getQuestCompanyBySlug, refreshQuestOnExtension };
