import { ApolloClient, ApolloLink, gql } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { CompanyQuestsPreview, QuestByCompanySlug, QuestCompany, Wave } from '../models/quests';
import { getWithHttpLink, initializeApollo, linkWithoutHttpLink } from '../services/apolloClient';
import { AirdropBalance, AirdropRank, AirdropWave } from '../models/airdrop';

const apolloClient = initializeApollo();

function getApolloClientForDevice(device_id: string) {
  let deviceIdHeaderLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        'authorization': undefined,
        'x-device-id': device_id,
      },
    }));

    return forward(operation);
  });

  const customApolloClient = new ApolloClient({
    ssrMode: apolloClient.ssrMode,
    link: getWithHttpLink(linkWithoutHttpLink.concat(deviceIdHeaderLink)),
    cache: apolloClient.cache,
    defaultOptions: apolloClient.defaultOptions,
  });

  return customApolloClient;
}

interface AirdropContextData {
  getAirdropBalance: (device_id?: string) => Promise<AirdropBalance>;
  getAirdropRank: (device_id?: string) => Promise<AirdropRank>;
  getAirdropWave: (wave_id: string, device_id?: string) => Promise<AirdropWave>;
}

const AirdropContext = createContext<AirdropContextData>({} as AirdropContextData);

export const AirdropProvider: React.FC = ({ children }) => {
  const getAirdropBalance = async (device_id?: string): Promise<AirdropBalance> => {
    return new Promise<AirdropBalance>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query AirdropBalance {
              airdropBalance {
                account_id
                currency
                amount
                user_position_rank
              }
            }
          `
      })
        .then(async response => {
          resolve(response?.data?.airdropBalance);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropWave = async (wave_id: string, device_id?: string): Promise<AirdropWave> => {
    return new Promise<AirdropWave>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query AirdropWave($wave_id: String!) {
              airdropWave(wave_id: $wave_id) {
                wave {
                  id
                  name
                  description
                  image
                  sort
                  is_completed
                }
                categories_and_quests {
                  quest_category {
                    id
                    name
                    sort
                  }
                  quests {
                    id
                    title
                    description
                    type
                    reward_currency
                    reward_amount
                    airdrop_reward {
                      id
                      account_id
                      currency
                      amount
                    }
                    quest_link {
                      initial_url
                      url
                      time_seconds
                    }
                    sort
                  }
                }
              }
            }
          `,
          variables: {
            wave_id,
          },
      })
        .then(async response => {
          let airdropWave = response?.data?.airdropWave;
          airdropWave.categories_and_quests = airdropWave.categories_and_quests.map(function (categoryAndQuests) {
            let newCategoriesAndQuests = categoryAndQuests;

            newCategoriesAndQuests.quests = newCategoriesAndQuests.quests.map(function (quest) {
              quest.is_rewarded = quest.airdrop_reward ? true : false;
              return quest;
            });

            return newCategoriesAndQuests;
          })

          resolve(response?.data?.airdropWave);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getAirdropRank = async (device_id?: string): Promise<AirdropRank> => {
    return new Promise<AirdropRank>((resolve, reject) => {
      const client = device_id ? getApolloClientForDevice(device_id) : apolloClient;

      client.query({
        query: gql`
            query AirdropRank {
              airdropRank {
                user_position_rank
                rank {
                  account_id
                  currency
                  amount
                  position
                }
              }
            }
          `
      })
        .then(async response => {
          resolve(response?.data?.airdropRank);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <AirdropContext.Provider value={{ getAirdropBalance, getAirdropRank, getAirdropWave }}>
      {children}
    </AirdropContext.Provider>
  );
};

function useAirdrop(): AirdropContextData {
  const context = useContext(AirdropContext);

  if (!context) {
    throw new Error('useAirdrop must be used within an AirdropProvider');
  }
  return context;
}

export { AirdropContext, useAirdrop };
